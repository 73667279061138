<template>
  <div class="rai-swipe-action__container">
    <div
      ref="actions"
      class="rai-swipe-action__action"
      :class="actionsClass"
      :style="actionWidth"
    >
      <div
        class="d-flex flex-column align-start justify-center"
        style="width: 100%; position: relative"
        @click.stop="
          () => {
            leadingAction.click();
            close();
          }
        "
      >
        <div
          class="rai-swipe-action__action-content d-flex flex-column"
          :class="actionContentClass"
        >
          <v-icon size="medium" color="white" v-text="leadingAction.icon" />
          <span
            class="text-caption align-self-center white--text"
            v-text="leadingAction.text"
          />
        </div>
      </div>
    </div>
    <div
      class="rai-swipe-action__content"
      :class="contentClass"
      :style="translation"
      v-bind="$attrs"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "RaiSwipeAction",
  inheritAttrs: false,
  props: {
    leadingAction: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    startX: 0,
    startY: 0,
    distX: 0,
    threshold: 60,
    draggingThreshold: 10,
    scrollingThreshold: 5,
    fullSwipeThreshold: 200,
    isDragging: false,
    isScrolling: false,
  }),
  computed: {
    translation() {
      return {
        transform: `translateX(${this.distX}px)`,
      };
    },
    actionWidth() {
      return {
        width: `${this.distX}px`,
      };
    },
    computedClass() {
      return ["align-start", "justify-start"];
    },
    meetsKeepOpenThreshold() {
      return this.distX > this.threshold;
    },
    meetsFullSwipeThreshold() {
      return this.distX > this.fullSwipeThreshold;
    },
    actionContentClass() {
      return {
        "rai-swipe-action__action-content--active":
          this.meetsFullSwipeThreshold,
      };
    },
    contentClass() {
      return {
        "rai-swipe-action__no_transition": this.isDragging,
      };
    },
    actionsClass() {
      return {
        "rai-swipe-action__no_transition": this.isDragging,
        [this.leadingAction.color]: true,
      };
    },
  },
  methods: {
    handleTouchStart(e) {
      const touchobj = e.changedTouches[0];
      this.startX = touchobj.pageX - this.distX;
      this.startY = touchobj.pageY;
    },
    // a touch is going to be one of three things:
    // 1. A scroll -- the user is trying to scroll the page, in this case we dont activate the swipe action
    // 2. A drag -- the user is trying to drag the swipe action, so we need to prevent the page from scrolling
    // 3. Neither -- we aren't sure what the user is trying to do yet, so we don't do anything
    handleTouchMove(e) {
      const touchobj = e.changedTouches[0];
      const newDistX = touchobj.pageX - this.startX - this.draggingThreshold;
      const newDistY = touchobj.pageY - this.startY;
      // dont do anything if we are scrolling
      if (this.isScrolling) {
        return;
      }
      // ensure we dont scroll the page if we are dragging
      if (this.isDragging) {
        e.preventDefault();
      }
      // if we are not dragging and we find that there is more movement in the Y direction than the X direction, we are scrolling
      if (Math.abs(newDistY) > this.scrollingThreshold && !this.isDragging) {
        this.isScrolling = true;
      }
      // if we are not dragging and we find that there is more movement in the X direction than the Y direction, we are dragging
      if (newDistX > 0 && !this.isDragging) {
        this.isDragging = true;
      }
      // if we don't know yet, we don't do anything
      if (!this.isDragging) {
        return;
      }
      if (newDistX > this.fullSwipeThreshold) {
        // after continuing to swipe has no effect, we add a deceleration factor to make the swipe action feel more natural
        const extraDist = newDistX - this.fullSwipeThreshold;
        const deceleration = Math.atan(extraDist / 100) * 20;
        this.distX = this.fullSwipeThreshold + deceleration;
      } else if (newDistX < 0) {
        this.distX = 0;
      } else {
        this.distX = newDistX;
      }
    },
    handleTouchEnd(e) {
      // a full swipe indicates that the user is trying to perform the action
      if (this.meetsFullSwipeThreshold) {
        this.distX = 0;
        this.leadingAction.click();
      }
      // if the user has dragged the swipe action past the threshold, we keep it open
      else if (this.meetsKeepOpenThreshold) {
        this.distX = this.threshold;
      } else {
        this.distX = 0;
      }
      this.isScrolling = false;
      this.isDragging = false;
    },
    close() {
      this.distX = 0;
    },
  },
};
</script>

<style>
.rai-swipe-action__container {
  overflow-x: hidden;
  position: relative;
}
.rai-swipe-action__content {
  transition: ease-in-out 0.3s;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
}
.rai-swipe-action__action {
  position: absolute;
  overflow-x: hidden;
  display: flex;
  width: 0;
  transition: ease-in-out 0.3s;
  height: 100%;
}
.rai-swipe-action__no_transition {
  transition: 0s;
}
.rai-swipe-action__action-content {
  position: absolute;
  width: 60px;
  left: 0;
  transition: 0.3s;
}
.rai-swipe-action__action-content--active {
  left: 70%;
}
</style>
