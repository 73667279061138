<script>
import { UPDATE_BACKSTOCK, REVERT_BACKSTOCK } from "./graphql";
import BackstockListItem from "./components/BackstockListItem.vue";
import { BACKSTOCKS } from "./graphql";
import { findIndex } from "lodash";
import { mapMutations } from "vuex";
import gql from "graphql-tag";
import {
  SORT_ORDER,
  VxDataList,
  PaginationOptionsUtils,
  LocalPaginationUtils,
  useResponsiveness,
} from "@/core-ui";
import RaiSwipeAction from "@/components/rai/RaiSwipeAction.vue";

import { buildRegex } from "./utils/string-parsing";
import { columns, defaultOrder, defaultSortPath } from "./utils/columns";

export default {
  name: "BackstockListDataList",
  components: {
    BackstockListItem,
    VxDataList,
    RaiSwipeAction,
  },
  mixins: [useResponsiveness()],
  props: {
    storeId: { type: String, default: undefined },
    categoryId: { type: String, default: undefined },
    locationId: { type: String, default: undefined },
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      backstocksLoading: 0,
      moveLoading: [],
      paginationOptions: PaginationOptionsUtils.buildDefaultPaginationOptions(),
      sortOptions: PaginationOptionsUtils.buildDefaultSortOptions({
        sortBy: defaultSortPath,
        sortOrder: defaultOrder,
      }),
    };
  },
  apollo: {
    allBackstocks() {
      return {
        loadingKey: "backstocksLoading",
        query: BACKSTOCKS,
        fetchPolicy: "cache-and-network",
        variables() {
          return this.backstockQueryVariables;
        },
        update(data) {
          LocalPaginationUtils.updateOptionsFromLocalData(
            this.paginationOptions,
            data.backstocks
          );
          return data.backstocks;
        },
      };
    },
  },
  computed: {
    mobileSort() {
      return this.$route.query.sort || defaultSortPath;
    },
    mobileOrder() {
      return this.$route.query.order || defaultOrder;
    },
    mobileSortOptions() {
      return {
        sortBy: this.mobileSort,
        sortOrder: this.mobileOrder,
      };
    },
    isSmallScreen() {
      // TODO - put this in some common lib
      return this.$vuetify.breakpoint.smAndDown;
    },
    headers() {
      return columns.map((column) => {
        return {
          ...column,
          label: this.$t(column.labelPath),
        };
      });
    },
    filteredBackstocks() {
      if (!this.allBackstocks) {
        return [];
      }

      const filteredBackstocks = this.allBackstocks.filter((b) => {
        const searchKey = `${b.bin.name} ${b.location.name} ${
          b.category.name
        } ${b.notes || ""}`;
        const searchRegex = buildRegex(this.parsedFilter.data || "");

        return (
          (!this.categoryId || b.category.id === this.categoryId) &&
          (!this.locationId || b.location.id === this.locationId) &&
          (!this.parsedFilter.data || searchKey.match(searchRegex))
        );
      });

      return filteredBackstocks;
    },
    backstocksPaginated() {
      // NOT GOOD to do mutation of pagination options here, but the easiest way to simulate server side like behaviour
      LocalPaginationUtils.updateOptionsFromLocalData(
        this.paginationOptions,
        this.filteredBackstocks
      );
      return LocalPaginationUtils.paginateLocalItems(
        this.filteredBackstocks,
        this.paginationOptions,
        this.sortOptions,
        {
          ignoreCase: true,
        }
      );
    },
    backstockQueryVariables() {
      return {
        storeId: this.storeId,
      };
    },
    maxBinNameLength() {
      const MAX_LENGTH = 11;
      return Math.min(
        MAX_LENGTH,
        this.backstocksPaginated.reduce((max, backstock) => {
          return Math.max(max, backstock.bin.name.length);
        }, 0)
      );
    },
  },
  watch: {
    categoryId() {
      this.onPageUpdate(1);
    },
    locationId() {
      this.onPageUpdate(1);
    },
    // TODO - find a better way to do this
    parsedFilter(newFilterValue) {
      this.onPageUpdate(1);
    },
    mobileSortOptions: {
      immediate: true,
      handler(val) {
        LocalPaginationUtils.updateSort(
          this.sortOptions,
          this.paginationOptions,
          val.sortBy,
          val.sortOrder
        );
      },
    },
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    async moveBackstock(backstock) {
      this.$set(backstock, "loading", true);
      const oldLocation = backstock.location;
      let updatedBackstock;
      try {
        const {
          data: {
            updateBackstock: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPDATE_BACKSTOCK,
          variables: {
            input: {
              id: backstock.id,
              locationId: backstock.location.nextLocation.id,
            },
          },
          // TODO - refetchQueries: [] ??
          update: (
            store,
            {
              data: {
                updateBackstock: { backstock },
              },
            }
          ) => {
            const variables = { ...this.backstockQueryVariables };
            updatedBackstock = backstock;

            // If the new location is terminal
            // - remove the backstock
            // - add the bin back in
            if (backstock.location.terminal) {
              // -- Remove backstock
              // define the backstock query
              const backstockQuery = gql`
                query ($storeId: ID!) {
                  backstocks(storeId: $storeId) {
                    id
                  }
                }
              `;
              // read from the cache
              const { backstocks = [] } = store.readQuery({
                query: backstockQuery,
                variables,
              });
              // try to find the backstock in the list
              const backstockIndex = findIndex(backstocks, {
                id: backstock.id,
              });
              // if found, remove it from the list
              if (backstockIndex > -1) {
                const beforeLength = backstocks.length;
                backstocks.splice(backstockIndex, 1);
                // write back to the cache, if `backstocks` changed
                if (beforeLength !== backstocks.length) {
                  store.writeQuery({
                    query: backstockQuery,
                    variables,
                    data: { backstocks: [...backstocks] },
                  });
                }
              }
              // -- Add the bin
              // define the bins query
              const binQuery = gql`
                query ($storeId: ID!) {
                  bins(storeId: $storeId, available: true) {
                    id
                  }
                }
              `;
              // read from the cache
              const { bins = [] } = store.readQuery({
                query: binQuery,
                variables,
              });
              // write the bins back to the cache
              store.writeQuery({
                query: binQuery,
                variables,
                data: { bins: [...bins, backstock.bin] },
              });
            }

            // -- Update the old location count
            // define the fragment
            const locationFragment = gql`
              fragment UpdateCount on Location {
                id
                binCount
              }
            `;
            // read the fragment
            const cachedLocation = store.readFragment({
              fragment: locationFragment,
              id: `Location:${oldLocation.id}`,
            });
            // decrement the binCount
            if (cachedLocation) {
              store.writeFragment({
                fragment: locationFragment,
                id: `Location:${oldLocation.id}`,
                data: {
                  ...cachedLocation,
                  binCount: cachedLocation.binCount - 1,
                },
              });
            }
          },
        });
        // Rethrow errors
        if (errors && errors.length) {
          throw new Error(errors.join("; "));
        }
        // If we made it this far, we were successful!
        //this.  = false;
        this.showSnackbar({
          text: this.$t("backstock.app.dataList.moveBackstockSuccessMessage"),
          button: "Undo",
          action: () => this.undoBackstockMove(updatedBackstock),
        });
      } catch (error) {
        this.showSnackbar({
          text: this.$t("backstock.app.dataList.moveBackstockErrorMessage", {
            error,
          }),
          button: null,
        });
      }
      this.$set(backstock, "loading", false);
    },
    async undoBackstockMove(backstock) {
      const oldLocation = backstock.location;
      this.$set(backstock, "loading", true);
      try {
        const {
          data: {
            revertBackstock: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: REVERT_BACKSTOCK,
          variables: {
            id: backstock.id,
          },
          // TODO - refetchQueries: [] ??
          update: (
            store,
            {
              data: {
                revertBackstock: { backstock },
              },
            }
          ) => {
            const variables = { ...this.backstockQueryVariables };
            // If the old location was terminal
            // - add the backstock back
            // - remove the bin
            if (oldLocation.terminal) {
              // -- Add backstock back
              // define the backstock query
              const backstockQuery = gql`
                query ($storeId: ID!) {
                  backstocks(storeId: $storeId) {
                    id
                  }
                }
              `;
              // read from the cache
              const { backstocks = [] } = store.readQuery({
                query: backstockQuery,
                variables,
              });
              // write back to the cache
              store.writeQuery({
                query: backstockQuery,
                variables,
                data: { backstocks: [backstock, ...backstocks] },
              });
              // -- Add the bin
              // define the bins query
              const binQuery = gql`
                query ($storeId: ID!) {
                  bins(storeId: $storeId, available: true) {
                    id
                  }
                }
              `;
              // read from the cache
              const { bins = [] } = store.readQuery({
                query: binQuery,
                variables,
              });
              // try to find the bin in the list
              const binIdx = findIndex(bins, {
                id: backstock.bin.id,
              });
              // if found, remove it from the list
              if (binIdx > -1) {
                const beforeLength = bins.length;
                bins.splice(binIdx, 1);
                // write back to the cache, if `bin` changed
                if (beforeLength !== bins.length) {
                  store.writeQuery({
                    query: binQuery,
                    variables,
                    data: { bins: [...bins] },
                  });
                }
              }
            }
            // -- Update the old location count
            // define the fragment
            const locationFragment = gql`
              fragment UpdateCount on Location {
                id
                binCount
              }
            `;
            // read the fragment
            const cachedLocation = store.readFragment({
              fragment: locationFragment,
              id: `Location:${oldLocation.id}`,
            });
            // decrement the binCount
            if (cachedLocation) {
              store.writeFragment({
                fragment: locationFragment,
                id: `Location:${oldLocation.id}`,
                data: {
                  ...cachedLocation,
                  binCount: cachedLocation.binCount - 1,
                },
              });
            }
          },
        });
        // Rethrow errors
        if (errors && errors.length) {
          throw new Error(errors.join("; "));
        }

        this.showSnackbar({
          text: this.$t(
            "backstock.app.dataList.undoBackstockMoveSuccessMessage"
          ),
          button: null,
        });
      } catch (e) {
        this.showSnackbar({
          text: this.$t(
            "backstock.app.dataList.undoBackstockMoveErrorMessage",
            {
              error,
            }
          ),
          button: null,
        });
      }
    },
    selectBackstock(backstock) {
      if (backstock && backstock.id) {
        this.$router.push({
          name: "backstock_edit",
          params: { backstockId: backstock.id },
          preserveQuery: true,
        });
      } else {
        this.$router.push({
          name: "backstock_home",
          preserveQuery: true,
        });
      }
    },
    onShowMore() {
      if (this.isSmallScreen) {
        LocalPaginationUtils.fetchMoreData(this.paginationOptions);
      }
    },
    onPageUpdate(page) {
      LocalPaginationUtils.updatePage(this.paginationOptions, page);
    },
    onItemsPerPageUpdate(itemsPerPage) {
      LocalPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        itemsPerPage
      );
    },
    onSortUpdate(header) {
      LocalPaginationUtils.updateSort(
        this.sortOptions,
        this.paginationOptions,
        header.propertyPath
      );
    },
  },
};
</script>

<template>
  <VxDataList
    class="backstock-list"
    :headers="headers"
    :pagination-options="paginationOptions"
    :sort-options="sortOptions"
    :loading="backstocksLoading"
    :empty-data-text="'No backstocks.'"
    :data-loading-text="'Loading backstocks.'"
    @update:page="onPageUpdate"
    @update:itemsPerPage="onItemsPerPageUpdate"
    @update:sort="onSortUpdate"
    @showMore="onShowMore"
  >
    <template #body>
      <template v-if="isDesktop">
        <BackstockListItem
          v-for="backstock in backstocksPaginated"
          :key="backstock.id"
          :backstock="backstock"
          @show="selectBackstock"
          @move="moveBackstock"
        />
      </template>
      <template v-else>
        <RaiSwipeAction
          v-for="backstock in backstocksPaginated"
          :key="backstock.id"
          :leading-action="{
            icon: '$vuetify.icons.moveBin',
            click: () => moveBackstock(backstock),
            color: 'secondary',
            text: backstock.location.nextLocation?.name,
          }"
        >
          <BackstockListItem
            :backstock="backstock"
            :max-bin-name-length="maxBinNameLength"
            @show="selectBackstock"
            @move="moveBackstock"
          />
        </RaiSwipeAction>
      </template>
    </template>
  </VxDataList>
</template>

<style lang="scss">
.backstock-list {
  .name-cell {
    width: 110px;
  }

  .category-cell {
    width: 200px;
  }

  .inv-date-cell {
    width: 120px;
  }

  .notes-cell {
    /* THIS one has auto width and is single row, with elipsis - just needs the inner and outer divs */
  }

  .location-cell {
    width: 150px;
  }

  .next-location-cell {
    width: 200px;
  }
}
</style>
