<script>
import { VxDataListItem } from "@/core-ui";
import get from "lodash/get";
import { format, isBefore, subDays, parseISO } from "date-fns";
import { max } from "lodash";

export default {
  name: "BackstockListItem",
  components: {
    VxDataListItem,
  },
  inheritAttrs: false,
  props: {
    backstock: {
      type: Object,
      default: () => ({}),
    },
    maxBinNameLength: {
      type: Number,
      default: 7,
    },
  },
  computed: {
    /**
     * - Dates are always in the past.
     * - Time of day is not important.
     * - Precision is not all that important  (i.e. the difference in 350 days ago and 340 days ago)
     *
     * If date is more than 1 year ago, show MMM YYYY
     * Otherwise, show MMM D
     * TODO: change to 11 months instead of 365 days.  (or even 6 months)
     *   - On January 19, 2020:
     *     Good:
     *       - Jan 19  (January 19, 2020)
     *       - Dec 20  (December 20, 2019)
     *       - Feb 19  (February 19, 2019)
     *       - Jan 2019  (January 30, 2019)
     *     Bad:
     *       - January 19  (January 19, 2020)
     *       - Jan 2020    (January 19, 2020)
     *       - Dec 2019    (December 20, 2019)
     *       - Jan 30      (January 30, 2019)
     *       - Jan 19      (January 30, 2019) - YY is too ambiguous
     */
    invDateMobile() {
      const invDate = parseISO(get(this, "backstock.invDate"));
      const strFormat =
        (isBefore(invDate, subDays(new Date(), 365)) && "MMM yyyy") || "MMM d";

      return invDate ? format(invDate, strFormat) : "";
    },
    invDateDesktop() {
      const val = get(this, "backstock.invDate");

      return (val && format(parseISO(val), "MM/dd/yy")) || "";
    },
    invDateColor() {
      const invDate = parseISO(get(this, "backstock.invDate"));
      const today = new Date();
      if (!invDate) return "";

      if (isBefore(invDate, subDays(today, 366)))
        return "pink--text text--darken-3";
      if (isBefore(invDate, subDays(today, 181)))
        return "deep-orange--text text--darken-2";
      if (isBefore(invDate, subDays(today, 91)))
        return "orange--text text--darken-3";
      return "success--text text--darken-2";
    },
    binName() {
      return get(this, "backstock.bin.name");
    },
    binNameStyle() {
      return {
        maxWidth: `${this.maxBinNameLength + 2}ch`,
      };
    },
    categoryName() {
      return get(this, "backstock.category.name");
    },
    notes() {
      return get(this, "backstock.notes");
    },
    locationName() {
      return get(this, "backstock.location.name");
    },
    nextLocationName() {
      return get(this, "backstock.location.nextLocation.name");
    },
    loading() {
      return get(this, "backstock.loading");
    },
  },
  methods: {
    get,
    moveBackstock() {
      this.$emit("move", this.backstock);
    },
    showBackstock() {
      this.$emit("show", this.backstock);
    },
  },
};
</script>

<template>
  <VxDataListItem
    v-bind="$attrs"
    class="rai-data backstock-item"
    v-on="$listeners"
    @click.stop="showBackstock"
  >
    <template #desktop>
      <td class="name-cell" v-text="binName" />
      <td class="category-cell" v-text="categoryName" />
      <td class="text-right inv-date-cell" v-text="invDateDesktop"></td>
      <td class="notes-cell">
        <div class="ellipsis-outer">
          <div class="ellipsis-inner">
            {{ notes }}
          </div>
        </div>
      </td>
      <td class="location-cell" v-text="locationName" />
      <td class="next-location-cell">
        <v-btn
          v-if="nextLocationName"
          text
          color="secondary"
          :loading="loading"
          @click.stop="moveBackstock"
        >
          <v-icon class="pr-2" v-text="'$vuetify.icons.moveBin'" />
          {{ nextLocationName }}
        </v-btn>
      </td>
    </template>

    <template #mobile>
      <v-list-item-content
        class="backstock-item__content pt-0"
        :style="binNameStyle"
      >
        <v-list-item-title>
          <span class="rai-data font-weight-bold" v-text="binName" />
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-content class="backstock-item__content pt-0">
        <v-list-item-title>
          <span class="rai-data" v-text="categoryName" />
        </v-list-item-title>
        <v-list-item-subtitle>
          <span class="rai-data" v-text="locationName" />
          <span class="rai-data ml-3 font-italic text-caption" v-text="notes" />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action-text
        :class="invDateColor"
        class="rai-data backstock-item__content"
        v-text="invDateMobile"
      />
    </template>
  </VxDataListItem>
</template>

<style>
.backstock-item {
  /* TODO - use padding classes, not direct value - everywhere! */
  padding-top: 8px;
  max-width: 100vw;
}
.backstock-item__content {
  align-self: start;
  padding-bottom: 8px;
}
</style>
